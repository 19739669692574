import { default as NextHead } from 'next/head';
import { useRouter } from 'next/router';
import { useSSRContext } from '../../hooks/useSSRContext';
import { LANGUAGE_EN, LANGUAGE_FI, LANGUAGE_FR } from '../../variables';

const defaultAlternateRefs = {
    [LANGUAGE_EN]: '/',
    [LANGUAGE_FR]: '/',
    [LANGUAGE_FI]: '/'
};

const Head = ({ children, title = '', description = '', alternateRefs = defaultAlternateRefs }) => {
    const router = useRouter();
    const { hostName } = useSSRContext();
    const [path] = router.asPath.split('?');
    const currentUrl = `${hostName}/${router.locale}${path}`;

    return (
        <NextHead>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            {description && <meta name="description" content={description} />}
            {description && <meta property="og:description" content={description} />}
            <link rel="canonical" href={currentUrl} />
            <title>{title}</title>
            {Object.keys(alternateRefs)
                .filter((key) => defaultAlternateRefs[key])
                .map((lang) => (
                    <link key={lang} rel="alternate" hrefLang={lang} href={`${hostName}/${lang}${alternateRefs[lang]}`} />
                ))}
            {children}
        </NextHead>
    );
};

export default Head;
