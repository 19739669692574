import { lazy } from 'react';
import { useSelector } from 'react-redux';
import { getSelectorsByUserAgent } from 'react-device-detect';
import Head from '../components/ui/Head';
import Layout from '../components/Layout';
import { getHostName } from '../utils/commonConfig';

const LobbyDesktop = lazy(() => import('../components/desktop/lobby'));
const LobbyMobile = lazy(() => import('../components/mobile/lobby'));

const LobbyPage = ({ device = {} }) => {
    const tr = useSelector((state) => state.global.data.translations);

    return (
        <Layout device={device}>
            <Head title={tr['title.home']} description={tr['meta.description.home']} />
            {device.isMobile ? <LobbyMobile device={device} /> : <LobbyDesktop />}
        </Layout>
    );
};

export const getServerSideProps = async ({ req }) => {
    const device = getSelectorsByUserAgent(req.headers['user-agent']);
    const hostName = getHostName(req.headers);

    return {
        props: {
            device,
            hostName
        }
    };
};

export default LobbyPage;
