import { useDispatch, useSelector } from 'react-redux';
import { setGeneralErrorModal } from '../../store/reducers/global';
import ContactSupportModal from './ContactSupportModal';

const GeneralErrorModal = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { generalErrorModal } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setGeneralErrorModal(false));
    };

    return (
        <ContactSupportModal
            isOpen={generalErrorModal}
            onClose={onClose}
            content={
                <div className="wdr-success-content">
                    <div>{tr['general_error_modal.details']}</div>
                </div>
            }
            tr={tr}
            title={tr['general_error_modal.title']}
        />
    );
};

export default GeneralErrorModal;
